import { Offcanvas } from "bootstrap";

export default function navbarInit() {

    const navLinks = document.querySelectorAll(".nav-item");
    const bsOffcanvas = new Offcanvas("#offcanvasNavbar");

    // Creates a Promise that resolves after a specified delay
    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    // Attach event listeners to close the offcanvas and scroll to the target element
    function toggleOffcanvas() {
        navLinks.forEach((link) => {
            link.addEventListener("click", (e) => {
                setTimeout(() => {
                    bsOffcanvas.hide();
                }, 400);
            }); 
        });
    }

    
    // window.innerWidth >= 1200 && animateDropdowns();
    window.innerWidth < 1200 && toggleOffcanvas();

    let oldWidth = window.innerWidth;

    window.addEventListener("resize", () => {
        if (window.innerWidth < 1200 && window.innerWidth !== oldWidth) {
            closeOffcanvas();
            oldWidth = window.innerWidth;
        }
    });
}
